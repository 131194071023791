import { AppKeyImsolo } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'imsolo-chat-client';
const PROD_WEB_PUSH_KEY = 'BNTkASPeU8RZReay9TsM9WCZBy0zdrnHMt913z6UU8XtzNlUEK3hMgfnRQCoz_ksxZ8ovNM-osSCH3JP7QBhEjU';
const DEV_WEB_PUSH_KEY = 'BH3YCla92jEarE2P4WaCwhGyKNBfPTHL6acUdZieHSaL_zji9xESDuSiPp_Uc8QaZPnXxNbXYqsSzA-5FUE9A0A';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyCpZWtdAm3R2T-UOIj14yMJEZPTGyAGozQ',
  authDomain: 'imsolo-chat.firebaseapp.com',
  projectId: 'imsolo-chat',
  storageBucket: 'imsolo-chat.appspot.com',
  messagingSenderId: '141155431457',
  appId: '1:141155431457:web:27d1aa64d2dcabd2469f60',
  measurementId: 'G-119B295R5W'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyCLDwV_CbBpEhiB7WjvoM0NcfCd9d5baII',
  authDomain: 'dev-imsolo-chat.firebaseapp.com',
  projectId: 'dev-imsolo-chat',
  storageBucket: 'dev-imsolo-chat.appspot.com',
  messagingSenderId: '198703238152',
  appId: '1:198703238152:web:4b66fafc9a13d6014815bd',
  measurementId: 'G-7ZED1JSN01'
};
const PROD_TALK_PLUS_APP_ID = 'f4dad66c-ff6d-4175-8e95-9031ef7101f8';
const DEV_TALK_PLUS_APP_ID = '98e56d8f-dc84-447a-bc23-655bb261ee70';

export const ImsoloProjectMeta: ProjectMeta = {
  id: AppKeyImsolo,
  mainPrimaryColor: '#F63459',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8103,
  apiName: 'imsolo-chat-api',
  apiPort: 5003,
  appId: 'io.pixelteam.imsolochat',
  appStoreUrl: 'https://apps.apple.com/app/id6467847898',
  appName: '나는솔로 팬채팅',
  appNameEn: 'SOLO CHAT',
  appSchemeName: 'SoloChat',
  testTargetUrl: 'https://test-imsolochat.pixelteam.io',
  devTargetUrl: 'https://dev-imsolochat.pixelteam.io',
  prodApi: 'https://imsolochat-api.pixelteam.io',
  devApi: 'https://dev-imsolochat-api.pixelteam.io',
  firebaseId: 'imsolo-chat',
  testHostname: 'test-imsolochat',
  clientSentryDsn: 'https://7a21529d200184dab79979059c48fdd4@o190729.ingest.sentry.io/4505946025820160',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11297/deploy',
    targets: ['prod-00', 'prod-01'],
    port: 7000,
    androidAppId: '1:141155431457:android:0919baff17d247e8469f60',
    iosAppId: '1:141155431457:ios:1953500dd112bb1b469f60',
    iosEncodingAppId: 'app-1-141155431457-ios-1953500dd112bb1b469f60',
    webAppId: '1:141155431457:web:27d1aa64d2dcabd2469f60',
    kakaoAppKey: 'e311acbdd242814e374faceba9f90136',
    googleReversedId: 'com.googleusercontent.apps.141155431457-bh4913qikf9c70v6at2iqgn2p1h0jp8q',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://imsolochat-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11284/deploy',
    targets: ['dev-00'],
    port: 7001,
    iosAppId: '1:198703238152:ios:0e61264462275a444815bd',
    androidAppId: '1:198703238152:android:82ae8ea509379a464815bd',
    iosEncodingAppId: 'app-1-198703238152-ios-0e61264462275a444815bd',
    webAppId: '1:198703238152:web:4b66fafc9a13d6014815bd',
    kakaoAppKey: '7d8d577b94a8b7939b9728c6d61da004',
    googleReversedId: 'com.googleusercontent.apps.198703238152-6409k773bob9oqms5v4r6ccpfdkj5lgo',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-imsolochat-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:198703238152:android:b59c213b9c61da5d4815bd',
    iosAppId: '1:198703238152:ios:4178bcc35892e72d4815bd',
    iosEncodingAppId: 'app-1-198703238152-ios-4178bcc35892e72d4815bd',
    kakaoAppKey: '7d8d577b94a8b7939b9728c6d61da004',
    googleReversedId: 'com.googleusercontent.apps.198703238152-tde24gujdv1q98qrcauds31d1isr2jfc',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-imsolochat-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~3968293183',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/6985338762',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~5391602796',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/3286315642',
  },
  NSCameraUsageDescription: '솔로채팅은 회원님의 기기 카메라를 사용하여 사진을 찍고, 동영상을 녹화하고, 효과를 사용할 수 있도록 지원합니다.',
  NSPhotoLibraryAddUsageDescription: '솔로채팅은 회원님이 솔로채팅에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '솔로채팅은 회원님이 솔로채팅에 사진과 동영상을 공유하고 효과를 적용할 수 있도록 지원하는 목적으로 회원님의 카메라 롤에 대한 액세스 권한을 사용합니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'SoloChat uses your access to save photos in SoloChat.',
  NSPhotoLibraryUsageDescriptionEn: 'Solo Chat uses access to your camera roll to enable you to share photos and videos in Solo Chat and apply effects to them.',
  consoleLogo: `
██╗███╗   ███╗███████╗ ██████╗ ██╗      ██████╗      ██████╗██╗  ██╗ █████╗ ████████╗
██║████╗ ████║██╔════╝██╔═══██╗██║     ██╔═══██╗    ██╔════╝██║  ██║██╔══██╗╚══██╔══╝
██║██╔████╔██║███████╗██║   ██║██║     ██║   ██║    ██║     ███████║███████║   ██║   
██║██║╚██╔╝██║╚════██║██║   ██║██║     ██║   ██║    ██║     ██╔══██║██╔══██║   ██║   
██║██║ ╚═╝ ██║███████║╚██████╔╝███████╗╚██████╔╝    ╚██████╗██║  ██║██║  ██║   ██║   
╚═╝╚═╝     ╚═╝╚══════╝ ╚═════╝ ╚══════╝ ╚═════╝      ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   
`
};
