import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { PHASE_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { IMSOLO_ADMIN_MENUS, ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyImsolo } from '@pixels/universal/model/apps/app-types';
import { ImsoloProjectMeta } from '@pixels/universal/model/apps/project-meta/imsolo-project-meta';
import { Phase } from '@pixels/universal/model/phase-model';

@Injectable({ providedIn: 'root' })
export class ImsoloAppInfo extends AppInfo {
  public readonly appType = AppKeyImsolo;
  public readonly serviceName = _('@솔로채팅@');
  public readonly appStoreUrl = signal(ImsoloProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${ImsoloProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? ImsoloProjectMeta.ios?.admobBannerVideoChatAppId : ImsoloProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly needContentAgreement: boolean = true;
  public override readonly loginTitle = _('@솔로채팅@');
  public override readonly loginDescription = _('@연애프로그램_이야기_n지역_또래_친구찾기@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/imsolo-app-icon.png';
  public override readonly bannerPlaceHolder = _('@솔로채팅_나는솔로_관상테스트_나솔_과몰입@');
  public override readonly adminMenus = [...ONE_ON_ONE_ADMIN_MENUS, ...IMSOLO_ADMIN_MENUS];
  public override readonly enableAppBanner = true;
  public override readonly appInstallTitle: string = _('@더_다양한_컨텐츠를_함께_솔로채팅_앱_다운받기@');
  public override readonly appInstallIconUrl: string = 'https://cdn.pixelteam.io/imsolo/assets/share/imsolo-share-icon-v2.png';
  public override readonly showShareButton = true;
  public override readonly agreementAge = 18;
  private readonly phase = inject(PHASE_TOKEN);
  public override readonly shareUrl = this.phase === Phase.prod ? 'https://solo.pixelteam.io' : 'https://dev-solo.pixelteam.io';
  public override readonly contentBaseUrl = this.phase === Phase.prod ? 'https://solo.pixelteam.io' : 'https://dev-solo.pixelteam.io';
}
